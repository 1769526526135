import {
  AUTHENTICATE_SERVICE_TOKEN,
  IAuthenticateService,
} from '@integration-frontends/common/auth/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { call, put } from 'redux-saga/effects';
import { init } from '@integration-frontends/common/app';
import { IDENTITY_STORE_TOKEN, IIdentityStore } from '../common';
import { getParam, PERSIST_ROUTE, sendMessage } from '../common/utilities';
import { SAVED_ROUTE_KEY } from './common';
import { take } from 'typed-redux-saga';
import { StorageMethods, useStorage } from '@apps-common/utils';
import { sendSegmentIdentifyAction } from '@integration-frontends/common/analytics';
import { push } from 'redux-first-history';

function* handleInit() {
  handleInitMessage();
  yield resolveStartRoute();
}

function handleInitMessage() {
  const initMsg = getParam('initMsg');
  initMsg && sendMessage({ event: 'initMsg', payload: initMsg });
}

function* resolveStartRoute() {
  const newParams = new URLSearchParams();
  const apiKeyFromUrlParams = newParams?.get('apikey');
  const token: string = apiKeyFromUrlParams ? apiKeyFromUrlParams : null;

  const identityStore: IIdentityStore = DI_CONTAINER.get(IDENTITY_STORE_TOKEN);
  const authService: IAuthenticateService = DI_CONTAINER.get(AUTHENTICATE_SERVICE_TOKEN);

  if (token) {
    const identity = yield call(authService.authenticate, token);
    yield call(identityStore.set, identity);
    yield put(sendSegmentIdentifyAction({ identity }));
  }

  const identity = yield call(identityStore.get);
  const isAuthed = yield call(authService.isAuthenticated, identity?.token);
  if (!isAuthed) {
    yield put(push('/getting-started'));
  } else if (PERSIST_ROUTE) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const savedRoute = useStorage(StorageMethods.GetItem, SAVED_ROUTE_KEY);
    console.log('Saved route : ' + savedRoute);
    if (savedRoute) yield put(push(savedRoute));
  } else {
    yield put(push('/select-attachment'));
  }
}

export function* initWorkflow() {
  yield take(init);
  yield handleInit();
}
