import { all, fork } from 'typed-redux-saga';
import { attachmentActionClickedWorkflow } from './attachment-action-clicked-workflow';
import { authWorkflow } from './auth-workflow';
import { backToHomeEffects } from './back-to-home';
import { externalLinkClickedEffects } from './external-link-clicked-effects';
import { initWorkflow } from './init-workflow';
import { placeAttachmentWorkflow } from './place-attachment-workflow';
import { routePersistenceEffects } from './route-persistence';

export function* rootWorkflow() {
  yield initWorkflow();
  yield all([
    fork(attachmentActionClickedWorkflow),
    fork(authWorkflow),
    fork(backToHomeEffects),
    fork(externalLinkClickedEffects),
    fork(placeAttachmentWorkflow),
    fork(routePersistenceEffects),
  ]);
}
