import {
  ALL_IMAGE_TYPES,
  areEqual,
  getExtensionMediaType,
  IMediaTypeSupportService,
  MediaType,
} from '@integration-frontends/integration/core/model';
import { injectable } from 'inversify';
import {
  DesignHuddleInitPayload,
  initEvent,
} from '../common/design-huddle-events';

@injectable()
export class MediaTypeSupportServiceDesignHuddleStrategy implements IMediaTypeSupportService {
  private payload: DesignHuddleInitPayload;

  constructor() {
    initEvent.then((payload) => (this.payload = payload));
  }

  getAllowedMediaTypes = (): MediaType[] | null => {
    return null;
  };

  getExportableMediaTypes = (): MediaType[] => {
    return ALL_IMAGE_TYPES;
  };

  isSupported = (mediaType: MediaType): boolean => {
    return this.payload
      ? !!this.payload.file_types
          .map(getExtensionMediaType)
          .find((dhFileType) => areEqual(dhFileType, mediaType))
      : false;
  };
}
