/**
 * Copied from legacy repo
 */

export const getParam = (name) => {
  var param = decodeURI((RegExp(name + '=' + '(.+?)(&|$)').exec(location.search) || [null])[1]);
  return param == 'undefined' ? undefined : param;
};

const channel = getParam('channel') || 'nativeLog';
const origin = getParam('origin');
export const openLinks = getParam('openLinks') !== 'false';
console.log('openLinks:', openLinks);
console.log(typeof openLinks);

export const attachmentFields = (getParam('attachment_fields') || '').split(',');
console.log('attachmentFields:', attachmentFields);

export const getExt = (filename) => {
  var idx = filename.lastIndexOf('.');
  // handle cases like, .htaccess, filename
  return idx < 1 ? '' : filename.substr(idx + 1).toUpperCase();
};

const allowedExtensions = (getParam('allowedExtensions') || '')
  .split(',')
  .filter(Boolean)
  .map((ext) => ext.trim().toUpperCase());

export const isAllowedExtension = (ext) =>
  !allowedExtensions.length ||
  allowedExtensions.map((ext) => ext.toLocaleUpperCase()).includes(ext?.toLocaleUpperCase());

const openableExtensions = (getParam('openableExtensions') || '')
  .split(',')
  .filter(Boolean)
  .map((ext) => ext.trim().toUpperCase());

export const isOpenableExtension = (ext) => openableExtensions.includes(ext);

export const PERSIST_ROUTE = !!getParam('persistRoute');
console.log("Persist route : " + PERSIST_ROUTE);

export const sendMessage = (msg) => {
  console.log('Sending:', msg);
  if (origin) {
    console.log('Sending to origin', origin);
    const targetWindow = window.parent || window.opener;
    targetWindow.postMessage(msg, origin);
  } else {
    window.postMessage(channel, msg);
  }
};
