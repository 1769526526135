import { takeEvery } from 'redux-saga/effects';
import { attachmentActionClicked } from '../actions';
import { fetchAttachmentsDetails } from '@integration-frontends/integration/core/application/select-attachment/state/effects/common/fetch-attachments-details';
import { call } from 'typed-redux-saga';

const handler = function* (action: ReturnType<typeof attachmentActionClicked>) {
  const [attachmentDetail] = yield call(fetchAttachmentsDetails, [action.payload.attachment]);
  const message = {
    event: 'selectedAttachmentForDesignHuddle',
    payload: {
      ...attachmentDetail.dto,
      ...attachmentDetail.dto.attributes,
      cdn_url: attachmentDetail.url,
      placementType: action.payload.action,
    },
  };

  console.log('Sending', message);
  const targetWindow = window.parent || window.opener;
  targetWindow.postMessage(message, '*');
};

export function* attachmentActionClickedWorkflow() {
  yield takeEvery(attachmentActionClicked, handler);
}
