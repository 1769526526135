import { SetApiKey } from '@integration-frontends/common/auth/ui';
import { BFPanel, Loader } from '@integration-frontends/common/ui';
import React, { Suspense, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { Navigate, Route, Routes, unstable_HistoryRouter as Router } from 'react-router-dom';
import { AttachmentUploader } from '../../../../libs/integration/ui/src/lib/attachment-uploader';
import { init } from '@integration-frontends/common/app';
import './app.scss';
import { getParam } from './common/utilities';
import { history, store } from './store';
import { SelectAttachment } from '../../../common/src';
import { Header } from '../../../common/src/ui/header';
import '../../logrocket/logrocketSetup';
import { loadSegment } from '@integration-frontends/common/analytics';
import { environment } from '../environments/environment';
import { initEvent } from '@integration-frontends/apps/integration-design-huddle/src/app/common/design-huddle-events';

export enum Size {
  Fixed = 'fixed',
  Full = 'full',
}

export function App() {
  const size = getParam('size') || Size.Fixed;
  const [receivedInitEvent, setReceivedInitEvent] = useState(false);
  loadSegment(environment);

  useEffect(() => {
    const message = {
      event: 'Panel Initialized',
    };
    console.log('Sending to origin', origin);
    const targetWindow = window.parent || window.opener;
    targetWindow.postMessage(message, '*');
  }, []);

  useEffect(() => {
    initEvent.then(() => {
      setReceivedInitEvent(true);
      store.dispatch(init({ name: 'integration-dh' }));
    });
  }, []);

  if (!receivedInitEvent) return null;

  return (
    <div
      style={{
        width: size === Size.Full ? '100%' : '85%',
      }}
    >
      <Provider store={store}>
        <Router history={history}>
          <BFPanel
            className="flex flex-col overflow-y-hidden"
            style={{ height: size === Size.Full ? '100%' : '100vh', minHeight: 640 }}
          >
            <Suspense
              fallback={
                <div className="w-full h-full flex items-center justify-center">
                  <Loader />
                </div>
              }
            >
              <Routes>
                <Route
                  path="/getting-started"
                  element={
                    <div id="getting-started-container">
                      <SetApiKey />
                    </div>
                  }
                />
                <Route path="/select-attachment/*" element={<SelectAttachment />} />
                <Route
                  path="/upload"
                  element={
                    <>
                      <Header />
                      <AttachmentUploader />
                    </>
                  }
                />
                <Route path="*" element={<Navigate to={'/select-attachment'} replace />} />
              </Routes>
            </Suspense>
          </BFPanel>
        </Router>
      </Provider>
    </div>
  );
}

export default App;
